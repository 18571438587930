body {
    margin: 0;
}

#app {
    width: 100vw;
    height: 100vh;
    padding: 24px;

    .ant-page-header {
        padding-left: 0;
    }

    code {
        margin-left: 20px;
    }
    overflow-x: hidden;
}

#filedrag {
    width: 500px;
    height: 500px;
    background: red;
}

#drop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(128, 128, 128, 0.3);
    font-size: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-shadow: 0 0 10px black;
    z-index: 100;
}
